.exercise-date-chart-component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.exercise-date-chart {
  text-align: center;
}
